const blackWords = {
  state: {
    blackwords: []
  },
  mutations: {
    SET_BLACK_WORDS: (state, list) => {
      state.blackwords = list.join('|')
    }
  },
  actions: {
    replaceBlackWord: ({
      dispatch,
      commit,
      state
    }, str) => {
      let reg = new RegExp(state.blackwords, 'gim')
      return str.replace(reg, '**')
    }
  }
};

export default blackWords;