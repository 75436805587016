import CryptoJS from 'crypto-js'

var key = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AES_KEY)
var iv = CryptoJS.enc.Utf8.parse(process.env.VUE_APP_AES_IV)


//AES加密
export function AESEncrypt(plaintext) {
    try {
        plaintext = JSON.stringify(plaintext)

        let resultByte = CryptoJS.AES.encrypt(plaintext, key, {
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
            iv: iv,
        });

        return encodeURIComponent(resultByte.toString()).trim()
    } catch (error) {
        return plaintext
    }
}


//AES解密
export function AESDecrypt(ciphertext) {
    try {
        let bytes = CryptoJS.AES.decrypt(decodeURIComponent(ciphertext), key, {
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
            iv: iv,
        })

        let plaintext = bytes.toString(CryptoJS.enc.Utf8)
        return JSON.parse(plaintext)
    } catch (error) {
        return ciphertext
    }
}