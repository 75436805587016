var os = function () {
    var ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox &&
            /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isSafari = ((/Safari/.test(ua) && !/Chrome/.test(ua))),
        isPc = !isPhone && !isAndroid && !isSymbian;
    return {
        isTablet,
        isPhone: isPhone,
        isAndroid: isAndroid,
        isPc,
        isChrome,
        isFireFox,
        isSafari,
        isSymbian,
        isWindowsPhone
    };
}();

export let isMobile = os.isAndroid || os.isPhone
export let isTablet = os.isTablet
export let isPc = os.isPc
export let isChrome = os.isChrome
export let isFireFox = os.isFireFox
export let isSafari = os.isSafari
export let isSymbian = os.isSymbian
export let isWindowsPhone = os.isWindowsPhone