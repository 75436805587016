import api from "@/utils/api";
export function accountCancel(params) {
  return api({
    url: "/user/account/cancel",
    method: "get",
    params,
  });
}

export function reset(params) {
  return api({
    url: "/user/password/reset",
    method: "get",
    params,
  });
}

export function typeList(params) {
  return api({
    url: "/user/feedback/type-list",
    method: "get",
    params,
  });
}

export function addFeedback(params) {
  return api({
    url: "/user/feedback/add",
    method: "get",
    params,
  });
}

export function getAccount(params) {
  return api({
    url: "/user/account/info",
    method: "get",
    params,
  });
}

export function payType(params) {
  return api({
    url: "/price/pay/type",
    method: "get",
    params,
  });
}

export function junior(params) {
  return api({
    url: "/price/junior/list",
    method: "get",
    params,
  });
}

export function order(params) {
  return api({
    url: "/order/web/add",
    method: "get",
    params,
  });
}

export function vip(params) {
  return api({
    url: "/price/vip/list",
    method: "get",
    params,
  });
}

export function check(params) {
  return api({
    url: "/order/payment/check",
    method: "get",
    params,
  });
}

export function verification(params) {
  return api({
    url: "/user/password-reset/verification-code",
    method: "get",
    params,
  });
}

export function getCountry(params) {
  return api({
    url: "/order/web/get-country",
    method: "get",
    params: params,
  });
}