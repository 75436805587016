import {
    createRouter,
    createWebHistory,
    createWebHashHistory,
} from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import store from "@/store";
import {
    getAccount
} from '@/api/setting'
import {
    isMobile
} from "@/utils/device";

const routes = [{
        path: "/",
        name: "Index",
        component: () => isMobile ? import('@/views/H5/Index') : import("@/views/Index"),
        meta: {
            title: "Chinese Dating |okmeete app",
            content: {
                keywords: "okmeete,ok meet,Chinese Dating,Asian dating,china dating app,Meet,match, Chat",
                description: "Okmeete app The most effective site for Chinese dating Search and browse all for free。Match, Chat, and Date. That’s our mantra.",
            }
        },
    },
    {
        path: "/discover",
        name: "Discover",
        meta: {
            title: "Discover |okmeete app",
            requiresAuth: true,
        },
        component: () => isMobile ? import("@/views/H5/Home/") : import("@/views/Home/"),
    },
    {
        path: "/edit",
        name: "Edit",
        meta: {
            title: "Edit |okmeete app",
            requiresAuth: true,
        },
        component: () => import("@/views/Edit/index.vue"),
    },
    {
        path: "/activity",
        name: "Likes",
        meta: {
            title: "Likes |okmeete app",
            requiresAuth: true,
        },
        component: () => import("@/views/Activity/index.vue"),
    },
    {
        path: "/setting",
        name: "Setting",
        meta: {
            title: "Setting |okmeete app",
            requiresAuth: true,
        },
        component: () => import("@/views/Settings/index.vue"),
    },
    {
        path: "/details/:touserid",
        name: "Details",
        meta: {
            title: "Details |okmeete app",
            requiresAuth: true,
        },
        component: () => isMobile ? import("@/views/H5/Details/Index.vue") : import("@/views/Details/Index.vue"),
    },
    {
        path: "/vip",
        name: "vip",
        component: () => isMobile ? import("@/views/H5/Vip/") : import("@/views/Vip/"),
        meta: {
            title: "VIP |okmeete app",
            requiresAuth: true,
        }
    },
    {
        path: "/pay/success/:orderid",
        name: "pay-success",
        meta: {
            title: "Success |okmeete app",
        },
        component: () => isMobile ? import("@/views/H5/Vip/PaySuccess.vue") : import("@/views/Vip/PaySuccess.vue")
    },
    {
        path: "/passport/signup",
        name: "signup",
        component: () => isMobile ? import('@/views/H5/Passport/Signup/') : import("@/views/Passport/Signup/"),
        meta: {
            title: "sign up |okmeete app",
        },
    },
    {
        path: "/passport/signin",
        name: "signin",
        component: () => isMobile ? import('@/views/H5/Passport/Signin/') : import("@/views/Passport/Signin/"),
        meta: {
            title: "sign in |okmeete app",
        },
    },
    {
        path: "/passport/forgot",
        name: "forgot",
        component: () => isMobile ? import('@/views/H5/Passport/Forgot/') : import("@/views/Passport/Forgot/"),
        meta: {
            title: "forgot password |okmeete app",
        },
    },
    {
        path: "/chat",
        name: "Chat",
        redirect: "/chat/conversation",
        component: () => import("@/views/Chat"),
        meta: {
            title: "chat |okmeete app",
        },
        children: [{
            path: "conversation",
            name: "Conversation",
            meta: {
                title: "Message |okmeete app",
                requiresAuth: true,
            },
            component: () => import("../views/Chat/components/Conversation"),
            children: [
                //系统通知详情框
                {
                    path: "informdetails",
                    component: () => import("../views/Chat/components/InformDetails"),
                },
                //聊天对话框
                {
                    path: "message",
                    component: () => import("../views/Chat/components/Message"),
                },
            ],
        }],
    },
    {
        path: "/page/privacy.html",
        name: "privacy",
        component: () => isMobile ? import("@/views/H5/Page/privacy/") : import("@/views/Page/privacy/"),
        meta: {
            title: "Privacy policy |okmeete app",
        },
    },
    {
        path: "/page/term.html",
        name: "term",
        component: () => isMobile ? import("@/views/H5/Page/term/") : import("@/views/Page/term/"),
        meta: {
            title: "Terms of Use Agreement |okmeete app",
        },
    },
    {
        path: "/page/about.html",
        name: "about",
        component: () => isMobile ? import("@/views/H5/Page/about/") : import("@/views/Page/about/"),
        meta: {
            title: "About us |okmeete app",
        },
    },
    {
        path: '/:error*',
        name: 'ERROR404',
        component: () => isMobile ? import("@/views/H5/Page/error") : import("@/views/Page/error"),
    },
    {
        path: '/discover/filter',
        name: "filter",
        component: () => isMobile ? import("@/views/H5/Filter/") : import("@/views/H5/Page/error"),
        meta: {
            title: "Filter |okmeete app",
            requiresAuth: true,
        },
    },
    {
        path: '/active/likes-you',
        name: "likes-you",
        component: () => import("@/views/H5/Activity/LikesYou.vue"),
        meta: {
            title: "Likes You |okmeete app",
            requiresAuth: true,
        },
    },
    {
        path: '/active/you-like',
        name: "you-like",
        component: () => import("@/views/H5/Activity/YouLikes.vue"),
        meta: {
            title: "You Like |okmeete app",
            requiresAuth: true,
        },
    },
    {
        path: '/active/viewed-you',
        name: "viewed-you",
        component: () => import("@/views/H5/Activity/ViewsYou.vue"),
        meta: {
            title: "Viewed You |okmeete app",
            requiresAuth: true,
        },
    },
    {
        path: '/active/blocked',
        name: "blocked",
        component: () => import("@/views/H5/Activity/Blocked.vue"),
        meta: {
            title: "Blocked List |okmeete app",
            requiresAuth: true,
        },
    }
];

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    NProgress.start();

    if (to.meta.content) {
        const head = document.getElementsByTagName("head");
        const meta = document.createElement("meta");
        document
            .querySelector('meta[name="keywords"]')
            .setAttribute("content", to.meta.content.keywords);
        document
            .querySelector('meta[name="description"]')
            .setAttribute("content", to.meta.content.description);
        meta.content = to.meta.content;
        head[0].appendChild(meta);
    }
    if (to.meta.title) {
        document.title = to.meta.title;
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        const loginState = store.state.loginState;
        if (!loginState) {
            let profile = JSON.parse(window.localStorage.getItem("OKMEETE_profile") || "{}")
            const loginUserFromStorage = JSON.parse(window.localStorage.getItem(`EASEIM_${profile.userid}_loginUser`) || "{}");
            if (loginUserFromStorage.user && loginUserFromStorage.accessToken) {
                EaseIM.conn.open({
                    user: loginUserFromStorage.user,
                    accessToken: loginUserFromStorage.accessToken,
                });
            } else {
                let profile = JSON.parse(localStorage.getItem("OKMEETE_profile") || "{}")
                EaseIM.conn.open({
                    user: profile.im_name,
                    pwd: profile.im_pwd,
                }).then(res => {
                    window.localStorage.setItem(`EASEIM_${profile.userid}_loginUser`, JSON.stringify({
                        user: profile.im_name,
                        accessToken: res.accessToken
                    }))
                })
            }
        }

        getAccount().then((res) => {
            if (res.code == 200) {
                let profile = JSON.parse(window.localStorage.getItem("OKMEETE_profile") || "{}")
                window.localStorage.setItem("OKMEETE_profile", JSON.stringify(Object.assign(profile, res.data.userInfo)))
                const userId = EaseIM.conn.user;
                store.dispatch("getMyUserInfo", userId);
                next();
            } else {
                next({
                    path: "/passport/signin",
                });
            }
        })

        NProgress.done();

        // } else {
        //   //token不存在，跳转登陆页面
        //   next({
        //     path: "/passport/signin",
        //   });
        //   NProgress.done();
        // }
    } else {
        next();
        NProgress.done();
    }
});
export default router;