const HomeData = {
  state: {
    activeName: localStorage.getItem("setting_activeName") || "1",
    activityActivityName: localStorage.getItem("activity_activeName") || "0",
    editActivityName: localStorage.getItem("edit_activeName") || "0",

    point1: parseInt(localStorage.getItem("act_point1")) || 0,
    point3: parseInt(localStorage.getItem("act_point3")) || 0,

    pointAll: localStorage.getItem("act_point_all") || 0,
  },

  mutations: {
    CHANGE_SETTING_ACTIVENAME: (state, activeName) => {
      localStorage.setItem("setting_activeName", activeName);
      state.activeName = activeName;
    },
    CHANGE_ACTIVITY_ACTIVENAME: (state, activeName) => {
      localStorage.setItem("activity_activeName", activeName);
      state.activityActivityName = activeName;
    },
    CHANGE_EDIT_ACTIVENAME: (state, activeName) => {
      localStorage.setItem("edit_activeName", activeName);
      state.editActivityName = activeName;
    },
    CHANGE_POINT1: (state, n) => {
      localStorage.setItem("act_point1", n);

      state.point1 = n;
      let all = state.point1 + state.point3;
      HomeData.mutations.CHANGE_POINTAll(state, all);
    },

    CHANGE_POINT3: (state, n) => {
      localStorage.setItem("act_point3", n);
      state.point3 = n;
      let all = state.point1 + state.point3;

      HomeData.mutations.CHANGE_POINTAll(state, all);
    },
    CHANGE_POINTAll: (state, n) => {
      if (n > 99) {
        n = "99+";
      }
      localStorage.setItem("act_point_all", n);
      state.pointAll = n;
    },
  },
};
export default HomeData;
