import ElemetnEn from 'element-plus/es/locale/lang/en';
import ElemetnZhCn from 'element-plus/es/locale/lang/zh-cn';
import ElemetnZhTw from 'element-plus/es/locale/lang/zh-tw';

import enLocale from './en.mjs'
import zhTwLocale from './zh-tw.mjs'
import zhCnLocale from './zh-cn.mjs'

const i18n = {
    'en': {
        ...ElemetnEn,
        ...enLocale
    },
    'zh-tw': {
        ...zhTwLocale,
        ...ElemetnZhTw
    },
    'zh-cn': {
        ...ElemetnZhCn,
        ...zhCnLocale
    }
}

let locale = window.localStorage.getItem('language') || 'en'

export default i18n[locale]