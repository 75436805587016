import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import router from '@/router'
// import md5 from 'md5'
import {
    AESDecrypt,
    AESEncrypt
} from './aes'
import {
    ElMessageBox
} from 'element-plus'
import EaseIM from '@/IM/initwebsdk'

const defaultBaseUrl = process.env.VUE_APP_API
// const DEFAULT_IOS_API_KEY = process.env.VUE_APP_KEY

// create an axios instance
const service = axios.create({
    withCredentials: false,
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    baseURL: `${defaultBaseUrl}`,
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 30000, // request timeout
    // headers: { 'Content-Type': 'application/json' }
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
})
// request interceptor
service.interceptors.request.use(
    config => {

        let params = {
            __source: 'web',
            __lang: 'en'
            // __version: '1.0.0',
        }

        let data = {}
        if (config.method == 'get') {
            data = config.params
        } else {
            data = config.data
        }

        params = _.merge(params, data)

        let userInfo = localStorage.getItem('OKMEETE_profile') || '{}'
        userInfo = JSON.parse(userInfo)
        if (userInfo.token) {
            params.token = userInfo.token
        }

        // var ordered = {}
        // _(params)
        //   .keys()
        //   .sort()
        //   .each(function (key) {
        //     ordered[key] = params[key]
        //   })

        // var query = new URLSearchParams(ordered)
        // query = query.toString()

        let timestamp = moment().unix()
        params['__ts'] = timestamp
        // params['__sig'] = md5(`${timestamp}${DEFAULT_IOS_API_KEY}${query}`)

        if (localStorage.getItem('OKMEETE_DEBUG') || false) {
            console.log(config.url)
            console.log(params)
        }


        if (process.env.NODE_ENV != 'development') {
            params = {
                query: AESEncrypt(params)
            }
        }

        if (config.method == 'get') {
            config.params = params
        } else {
            config.data = params
        }

        return config
    },
    error => {
        // do something with request error
        console.log('request error', error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        var res = response.data

        if (process.env.NODE_ENV != 'development') {
            res = AESDecrypt(res)
        }

        if (localStorage.getItem('OKMEETE_DEBUG') || false) {
            console.log(res)
        }

        if (res.code == '10110') {
            EaseIM.conn.close()
            ElMessageBox.close()
            ElMessageBox.confirm(
                'Login status has expired, please log in again.',
                'Warning', {
                    confirmButtonText: 'Login',
                    showCancelButton: false,
                    type: 'warning',
                }
            ).then(() => {
                router.push({
                    path: '/passport/signin'
                })
            })
        }
        return res
        // }
    },
    error => {
        // if (error.response) {
        //     const res = error.response.data // for debug
        //     if (error.response.status === 401 && res.code !== '001') {
        //         console.log('>>>>>无权限');
        //     }
        //     if (error.response.status === 403) {
        //         res.desc = '您没有权限进行查询和操作!'
        //     }
        //     return Promise.reject(res.desc || error)
        // }
        return Promise.reject(error)
    }
)

export default service