import api from '@/utils/api'

// export function loginMasterApi(params) {
//     return api({
//         url: '/login',
//         method: 'get',
//         params: params
//     })
// }

// export function checkMasterApi(params) {
//     return api({
//         url: '/chatkit/check',
//         method: 'get',
//         params: params
//     })
// }

// export function vestListApi(params) {
//     return api({
//         url: '/get-vest-list',
//         method: 'get',
//         params: params
//     })
// }

// export function vestLogin(params) {
//     return api({
//         url: '/vest-login',
//         method: 'get',
//         params: params
//     })
// }

export function getUserInfo(params) {
    // params.role = 'vest'
    return api({
        url: '/chatkit/get-user-info',
        method: 'get',
        params: params
    })
}

export function getUserInfoById(params) {
    // params.role = 'vest'
    return api({
        url: '/chatkit/get-user-info-by-id',
        method: 'get',
        params: params
    })
}

// export function getUserSurplus(params) {
//     params.role = 'vest'
//     return api({
//         url: '/get-user-surplus',
//         method: 'get',
//         params: params
//     })
// }

export function syncConversation(params) {
    // params.role = 'vest'
    return api({
        url: '/chatkit/sync-conversation',
        method: 'get',
        params: params
    })
}

export function addContact(params) {
    // params.role = 'vest'
    return api({
        url: '/chatkit/add-contact',
        method: 'get',
        params: params
    })
}

export function addConversation(params) {
    // params.role = 'vest'
    return api({
        url: '/chatkit/add-conversation',
        method: 'get',
        params: params
    })
}

export function getContacts(params) {
    // params.role = 'vest'
    return api({
        url: '/chatkit/get-contacts',
        method: 'get',
        params: params
    })
}

export function delContact(params) {
    // params.role = 'vest'
    return api({
        url: '/chatkit/del-contact',
        method: 'get',
        params: params
    })
}

// export function getNewUsers(params) {
//     params.role = 'vest'
//     return api({
//         url: '/get-new-users',
//         method: 'get',
//         params: params
//     })
// }

// export function getCertUsers(params) {
//     params.role = 'vest'
//     return api({
//         url: '/get-cert-users',
//         method: 'get',
//         params: params
//     })
// }

// export function getHotUsers(params) {
//     params.role = 'vest'
//     return api({
//         url: '/get-hot-users',
//         method: 'get',
//         params: params
//     })
// }

// export function addVisit(params) {
//     params.role = 'vest'
//     return api({
//         url: '/add-visit',
//         method: 'get',
//         params: params
//     })
// }

// export function addLike(params) {
//     params.role = 'vest'
//     return api({
//         url: '/add-like',
//         method: 'get',
//         params: params
//     })
// }

//建立聊天关系
export function addChat(params) {
    return api({
        url: '/user/chat/send',
        method: 'get',
        params: params
    })
}

//判断聊天
export function judge(params) {
    return api({
        url: '/user/chat/judge',
        method: 'get',
        params: params
    })
}

export function canSendImg(params) {
    return api({
        url: '/user/chat/can-you-send',
        method: 'get',
        params: params
    })
}

