<script setup>
import { computed, ref, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import router from "@/router";
import EaseIM from "@/IM/initwebsdk";
// import { handleSDKErrorNotifi, setMessageKey } from "@/utils/handleSomeData";
import { informType } from "@/constant";
import { usePlayRing } from "@/hooks";
import ring from "@/assets/ring.mp3";
import { syncConversation } from "@/api/chat";
// import { match } from "pinyin-pro";
import { AESDecrypt, AESEncrypt } from "./utils/aes";
import { getBlackWords } from "./api/blackWords";
import logo from '@/assets/logo.png'
import systemAvatar from "@/assets/images/avatar/inform.png";

const store = useStore();
const friendList = computed(() => store.state.Contacts.friendList);

const { isOpenPlayRing, clickRing } = usePlayRing();
EaseIM.logger.disableAll();
/* connect 相关监听 */
EaseIM.conn.addEventHandler("connection", {
    onConnected: () => {
        console.log("IM login success");
        store.commit("CHANGE_LOGIN_STATUS", true);
        if (isOpenPlayRing.value) clickRing();
        fetchLoginUsersInitData();
        // router.push('/home');
    },
    onDisconnected: () => {
        store.commit("CHANGE_LOGIN_STATUS", false);
    },
    onOnline: () => {
        store.commit("CHANGE_NETWORK_STATUS", true);
    }, // 本机网络连接成功。
    onOffline: () => {
        store.commit("CHANGE_NETWORK_STATUS", false);
    }, // 本机网络掉线。
    onError: (error) => {
        console.log("on error", error);
        // handleSDKErrorNotifi(error.type, error.message);
    },
});
//fetch 登陆用户的初始数据
const fetchLoginUsersInitData = () => {
    // 获取当前登录用户信息
    getMyUserInfos();

    // 更新通讯录列表，避免没有新消息的情况下，会话列表没头像
    fetchFriendList();

    //初始化vuex中的会话列表相关数据
    store.commit("INIT_CONVERSATION_STATE");
};

//获取登陆用户属性
const getMyUserInfos = () => {
    const userId = EaseIM.conn.user;
    store.dispatch("getMyUserInfo", userId);
};
//获取好友列表
const fetchFriendList = () => {
    // const { value = {} } = useLocalStorage('friendList')
    // if (Object.values(JSON.parse(value)).length > 0) return
    store.dispatch("fetchFriendList");
};

//在线状态订阅相关
const presenceStatus = (type, user) => {
    type === "sub" && store.dispatch("subFriendsPresence", [user]);
    type === "unsub" && store.dispatch("unsubFriendsPresence", [user]);
};

/* presence 相关监听 */
EaseIM.conn.addEventHandler("presenceStatusChange", {
    onPresenceStatusChange: (status) => {
        console.log(">>>>>presenceStatusChange", status);
        getUserPresence(...status);
    },
});
//处理登陆用户状态的变更
const getUserPresence = (status) => {
    store.dispatch("handlePresenceChanges", status);
};
/* message 相关监听 */
EaseIM.conn.addEventHandler("messageListen", {
    onTextMessage: function (message) {
        // console.log('>>>>>>>App mesage', message)
        // console.log('setMessageKey', setMessageKey(message))
        pushNewMessage(message);
    }, // 收到文本消息。
    onEmojiMessage: function (message) {
        pushNewMessage(message);
    }, // 收到表情消息。
    onImageMessage: function (message) {
        pushNewMessage(message);
    }, // 收到图片消息。
    onCmdMessage: function (message) {
        console.log(">>>>>收到命令消息", message);
    }, // 收到命令消息。
    onAudioMessage: function (message) {
        pushNewMessage(message);
    }, // 收到音频消息。
    onLocationMessage: function (message) {
        pushNewMessage(message);
    }, // 收到位置消息。
    onFileMessage: function (message) {
        pushNewMessage(message);
    }, // 收到文件消息。
    onCustomMessage: function (message) {
        pushNewMessage(message);
    }, // 收到自定义消息。
    onVideoMessage: function (message) {
        pushNewMessage(message);
    }, // 收到视频消息。
    onRecallMessage: function (message) {
        otherRecallMessage(message);
    }, // 收到消息撤回回执。
});

const syncMode = computed(() => {
    let mode = window.localStorage.getItem("OKMEETE_syncMode");

    if (mode == null) {
        window.localStorage.setItem("OKMEETE_syncMode", 1);
        return 1;
    }

    return mode;
});

//接收的消息往store中push
var imNames = [];
var syncTimer = ref({
    interval: null,
    time: 3000,
});
const pushNewMessage = async (message) => {
    if (syncMode.value == 1) {
        console.log("异步");
        // 对方不是好友，且不是小红娘，异步同步会话列表、延迟5秒刷新
        if (message.from != "admin" && !friendList.value[message.from]) {
            imNames.push(message.from);

            if (Object.keys(imNames).length) {
                console.log("待同步会话列表:" + imNames.length);

                // 清空前序同步定时器
                if (syncTimer.value.interval) {
                    clearTimeout(syncTimer.value.interval);
                }

                // 延迟5秒同步
                syncTimer.value.interval = setTimeout(async function () {
                    let imArr = _.chunk([...imNames], 80);
                    if (imArr.length > 0) {
                        imArr.map(async (items) => {
                            await syncConversation({ im_name: items.join(",") });
                            imNames = _.difference(imNames, items);
                            // 同步完成后更新列表
                            if (Object.keys(imNames).length == 0) {
                                fetchFriendList();
                            }
                        });
                    }
                }, syncTimer.value.time);
            }
        }
    } else {
        console.log("同步");
        if (message.from != "admin" && !friendList[message.from]) {
            syncConversation({ im_name: message.from });

            // 清空前序列表更新定时器
            if (syncTimer.value.interval) {
                clearTimeout(syncTimer.value.interval);
            }

            // 延迟5秒更新列表
            syncTimer.value.interval = setTimeout(async function () {
                fetchFriendList();
            }, syncTimer.value.time);
        }
    }

    let avatar = logo
    if (message.from == "admin") {
        store.commit("SET_BADGE_SYSTEM", 1);
        avatar = systemAvatar
    } else {
        store.commit("SET_BADGE_MESSAGE", 1);
        let profile = JSON.parse(window.localStorage.getItem(`EASEIM_${EaseIM.conn.user}_conversationList`) || '{}')
        if (profile[message.from]?.conversationInfo?.avatarUrl) {
            avatar = profile[message.from]?.conversationInfo?.avatarUrl
        }
    }


    try {
        let itemKey = AESEncrypt(message.from);
        showMsgNotification(
            "okmeete",
            "You have a new message.",
            `/chat/conversation/message?id=${itemKey}&chatType=singleChat`,
            avatar
        );
    } catch (err) { }

    store.dispatch("createNewMessage", message);
};

function showMsgNotification(title, body, path, avatar) {
    var options = {
        body: body,
        requireInteraction: false,
        icon: avatar,
        tag: "hangge",
    };
    var Notification =
        window.Notification || window.mozNotification || window.webkitNotification;
    if (Notification) {
        if (Notification.permission == "granted") {
            instance_init(title, options, path);
        } else {
            Notification.requestPermission().then((status) => {
                if (status === "granted") {
                    instance_init(title, options);
                } else {
                    return false;
                }
            });
        }

        function instance_init(title, options, path) {
            var instance = new Notification(title, options);
            instance.onclick = function () {
                router.push(path);
                instance.close();
            };
            // instance.onerror = function () {
            //     console.log('onerror');
            // };
            // instance.onshow = function () {
            //     console.log('onshow');
            // };
            // instance.onclose = function () {
            //     console.log('onclose');
            // };
        }
    } else {
        console.log("Unsupport browser!");
    }
}

//收到他人的撤回指令
const otherRecallMessage = (message) => {
    console.log(">>>>>收到他人撤回", message);
    const { from, to, mid } = message;
    //单对单的撤回to必然为登陆的用户id，群组发起撤回to必然为群组id 所以key可以这样来区分群组或者单人。
    let key = to === EaseIM.conn.user ? from : to;
    console.log(">>>>>收到他人撤回", key);
    store.commit("CHANGE_MESSAGE_BODAY", { type: "recall", key, mid });
    store.dispatch("gatherConversation", key);
};
/* 好友关系相关监听 */
const { INFORM_FROM } = informType;
EaseIM.conn.addEventHandler("friendListen", {
    // 收到好友邀请触发此方法。
    onContactInvited: (data) => {
        //写入INFORM
        console.log(">>>>>>收到好友申请", data);
        submitInformData(INFORM_FROM.FRIEND, data);
    },
    // 联系人被删除时触发此方法。
    onContactDeleted: (data) => {
        //写入INFORM
        console.log(">>>>收到好友关系解散", data);
        submitInformData(INFORM_FROM.FRIEND, data);
        //取消针对好友的在线状态订阅
        presenceStatus("unsub", data.from);
        //好友关系解除重新获取好友列表
        fetchFriendList();
    },
    // 新增联系人会触发此方法。
    onContactAdded: (data) => {
        console.log(">>>>好友新增监听", data);
        submitInformData(INFORM_FROM.FRIEND, data);
        //新增好友重新获取好友列表
        fetchFriendList();
    },
    // 好友请求被拒绝时触发此方法。
    onContactRefuse: (data) => {
        //写入INFORM
        console.log(">>>>>>好友申请被拒绝", data);
        data.type = "other_person_refuse";
        submitInformData(INFORM_FROM.FRIEND, data);
    },
    // 好友请求被同意时触发此方法。
    onContactAgreed: (data) => {
        //写入INFORM
        console.log(">>>>>对方同意了好友申请", data);
        //改掉data中的type
        data.type = "other_person_agree";
        submitInformData(INFORM_FROM.FRIEND, data);
        //对方同意后重新获取好友列表
        fetchFriendList();
    },
});
/* 群组相关监听 */
EaseIM.conn.addEventHandler("groupEvent", {
    onGroupEvent: (groupevent) => {
        console.log(">>>>>>>收到群组事件", groupevent);
        submitInformData(INFORM_FROM.GROUP, groupevent);
    },
});

const submitInformData = (fromType, informContent) => {
    console.log(">>>submitInformData>>>", fromType, informContent);
    store.dispatch("createNewInform", { fromType, informContent });
};

/* 重新登陆 */
//读取本地EASEIM_loginUser
// const EASEIM_loginUser =
//   window.localStorage.getItem("EASEIM_loginUser") || "{}";
// const loginUserFromStorage = JSON.parse(EASEIM_loginUser);
// if (loginUserFromStorage.user)
//   loginUserFromStorage.user = AESDecrypt(loginUserFromStorage.user);
// const handleRelogin = () => {
//   console.log("Im relogin");
//   EaseIM.conn.open({
//     user: loginUserFromStorage.user,
//     accessToken: loginUserFromStorage.accessToken,
//   });
// };

// const profile = window.localStorage.getItem("OKMEETE_profile") || "{}";
// const userInfo = JSON.parse(profile);

// if (
//   loginUserFromStorage?.user &&
//   loginUserFromStorage?.accessToken &&
//   userInfo?.im_name == loginUserFromStorage?.user
// ) {
//   handleRelogin();
// } else {
//   window.localStorage.removeItem('OKMEETE_profile');
//   window.localStorage.removeItem('EASEIM_loginUser');
//   // router.push("/");
// }

const show = ref(true);
onUnmounted(() => {
    show.value = false;
});

onMounted(() => {
    const words = getBlackWords()
    store.commit('SET_BLACK_WORDS', words)
})

</script>
<template :v-if="show">
    <router-view v-slot="{ Component }">
        <transition name="slide-fade" mode="out-in" :duration="{ enter: 500, leave: 300 }">
            <component :is="Component" />
        </transition>
    </router-view>
    <audio id="ring" :src="ring" controls hidden></audio>
</template>

<style type="scss">
@import "./styles/reset/reset.css";
@import "./styles/iconfont/iconfont.css";
</style>
<style>
.el-message-box.avatar-zoom.el-message-box--center {
    border: 0;
    height: auto;
    margin: 0;
    padding: 0;
}

.avatar-zoom .el-message-box__content {
    padding: 0 !important;
    margin: 0 !important;
}

.avatar-zoom .el-message-box__message p {
    line-height: 0;
}

.avatar-zoom .el-message-box__btns {
    display: none;
}
</style>
