const Badge = {
    state: {
        system: 0,
        message: 0,
    },
    mutations: {
        SET_BADGE_SYSTEM: (state, badge) => {
            if (badge)
                state.system = state.system + badge
            else
                state.system = 0
        },
        SET_BADGE_MESSAGE: (state, badge) => {
            state.message = state.message + badge
        }
    }
};

export default Badge;