import {
    createApp
} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
// import './styles/element/index.scss';
import VueLazyload from 'vue-lazyload'
// import {
//     createI18n
// } from 'vue-i18n'

import locale from '@/locale/lang'

import VueQuickPicker from 'vue-quick-picker';

import {
    isMobile
} from '@/utils/device'
if (isMobile) {
    import('@/styles/element/mobile.scss')
} else {
    import('@/styles/element/pc.scss')
}
const app = createApp(App)
    .use(store)
    .use(router)
    // .use(VueCropper)
    .use(VueLazyload) //懒加载，el-image不好用
    .use(ElementPlus, {
        locale: {
            name: locale.name,
            el: locale.el
        }
    })
    .use(VueQuickPicker)
    // .use(createI18n({
    //     locale: locale.name,
    //     messages: locale.meete
    // }))
    .mount('#app');